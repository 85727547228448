/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function($) {

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    'common': {
      init: function() {
        // JavaScript to be fired on all pages
      },
      finalize: function() {
        $(window).load(function(){
          $(document).foundation();

          $('.btn_menu').click(function(){
            if($(this).hasClass('close')){
              $(this).removeClass('close');
              $('.hidden_nav').fadeOut();
            }else{
              $(this).addClass('close');
              $('.hidden_nav').fadeIn();
            }
          });

          $('#menu-item-827 a').attr({'id' : 'evgb_phone'});
          /*
          var tl = new TimelineLite({onComplete:function(){
            this.restart();
            }
          });

          tl.to($('.logo_02'), 0, {opacity:1, delay:1})
          .to($('.logo_03'), 0, {opacity:1, delay:1})
          .to($('.logo_02, .logo_03'), 0, {opacity:0, delay:1}, 'change_01')
          .to($('.block_bkg_02'), 0, {opacity:1, delay:1}, 'change_01')
          .to($('.logo_01, .logo_02, .logo_03'), 0, {css:{color:"#d12328"}, delay:1}, 'change_01')
          .to($('.block_01'), 0, {backgroundColor:"#e8e560", delay:1}, 'change_01')
          .to($('.block_03'), 0, {backgroundColor:"#2ab4c3", delay:1}, 'change_01')
          .to($('.logo_02'), 0, {opacity:1, delay:1})
          .to($('.logo_03'), 0, {opacity:1, delay:1})
          .to($('.logo_02, .logo_03'), 0, {opacity:0, delay:1}, 'change_02')
          .to($('.block_bkg_03'), 0, {opacity:1, delay:1}, 'change_02')
          .to($('.logo_01, .logo_02, .logo_03'), 0, {css:{color:"#0e4869"}, delay:1}, 'change_02')
          .to($('.block_01'), 0, {backgroundColor:"#e8e560", delay:1}, 'change_02')
          .to($('.block_03'), 0, {backgroundColor:"#f15f69", delay:1}, 'change_02')
          .to($('.logo_02'), 0, {opacity:1, delay:1})
          .to($('.logo_03'), 0, {opacity:1, delay:1})
          .to($('.logo_02, .logo_03'), 0, {opacity:0, delay:1}, 'change_03')
          .to($('.block_bkg_04'), 0, {opacity:1, delay:1}, 'change_03')
          .to($('.logo_01, .logo_02, .logo_03'), 0, {css:{color:"#e6df36"}, delay:1}, 'change_03')
          .to($('.block_01'), 0, {backgroundColor:"#0e4969", delay:1}, 'change_03')
          .to($('.block_03'), 0, {backgroundColor:"#6db6aa", delay:1}, 'change_03')
          .to($('.logo_02'), 0, {opacity:1, delay:1})
          .to($('.logo_03'), 0, {opacity:1, delay:1})
          .to($('.logo_03'), 0, {opacity:1, delay:1}, 'change_04')
          .to($('.block_bkg_02, block_bkg_03, block_bkg_04'), 0, {opacity:0, delay:1}, 'change_04');

          $("input, select").focusin(function() {
            //console.log('stop animation');
            tl.pause();
          });
          $("input, select").focusout(function() {
            //console.log('resume animation');
            tl.resume();
          });
          */
        });
      }
    },
    // Home page
    'home': {
      init: function() {
        // JavaScript to be fired on the home page
      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS
      }
    },
    // About us page, note the change from about-us to about_us.
    'about_us': {
      init: function() {
        // JavaScript to be fired on the about us page
      }
    }
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.
